import React from 'react';
import { Link } from 'gatsby';
import Img from 'gatsby-image';
import Layout from '../components/layout';
import Menu from '../components/menu';
import Footer from '../components/footer';

const ContactPage = ({ location }) => (
    <Layout>
        <Menu location={location} />

        <h1 className="huge red padding-bottom-0">404</h1>
        <p className="hero">We couldn’t find this page</p>
        <p>
            Make sure you've typed the URL&nbsp;correctly. You can always find
            more on our homepage.
        </p>
        <p className="link">
            <Link to="/" title="Go to homepage">
                Go to homepage&nbsp;&nbsp;
                <svg>
                    <use
                        xmlnsXlink="http://www.w3.org/1999/xlink"
                        xlinkHref="#link-next"
                    ></use>
                </svg>
            </Link>
        </p>

        <div className="spacer spacer-80">&nbsp;</div>
        <Footer />
    </Layout>
);

export default ContactPage;
